// ----------  VARIABLES  ----------
$color-general: #404244;
$color-general-claro: #7B7C7D;
$color-primary: #FF0033;

@keyframes pulse {
  0% {
    clip-path: circle(50% at center);
  }
  50% {
    clip-path: circle(48% at center);
  }
  100% {
    clip-path: circle(50% at center);
  }
}
@keyframes lineTitle {
  0% {
		//left: calc(100% - 100px);
		left: -50px;
    width: 0px;
  }
  100% {
		//left: calc(100% - 65px);
		left: 0;
    width: 150px;
  }
}
@keyframes pulseImg {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes clipTop {
  0% {
		opacity: .5;
		left: 50%;
    top: -120%;
  }
  100% {
		opacity: 1;
		left: 150px;
    top: -30%;
  }
}

@keyframes clipFigure {
  0% {
		//left: 50%;
    //top: -120%;
		opacity: 0;
		height: 110vh;
		top: -5vh;
		width: calc(50vw - 0vw);
  }
	60% {
		opacity: 1;
	}
  100% {
		opacity: 1;
		height: 70vh;
		top: 15vh;
		width: calc(50vw - 100px);
		//left: 100px;
    //top: -30%;
  }
}

@keyframes textInt {
  0% {
		opacity: 0;
		top: 60px;
  }
	40% {
		opacity: 0;
	}
  100% {
		opacity: 1;
		top: 0;
  }
}

@keyframes lineSubtitle {
  0% {
		left: -150px;
    width: 0px;
  }
  100% {
		left: 0;
    width: 120px;
  }
}

@keyframes planButton {
  0% {
		right: -2px;
  }
  100% {
		right: 100px;
  }
}

@keyframes planButtonReverse {
  0% {
		right: 100px;
  }
  100% {
		right: -2px;
  }
}

@keyframes clipCircle {
  0% {
    left: -50vw;
		top: -900px;
  }
  50% {
    left: 0;
  }
  100% {
    left: 0;
		top: 0;
  }
}

@keyframes loadTextButton {
  0% {
    color: transparent;
  }
  100% {
    color: #FFF;
  }
}

@keyframes loadButton {
  0% {
    width: 0;
    height: 0;
    box-shadow: 0 0 0px 0 rgba(255,0,51,0);
  }
  50% {
    width: 68px;
    height: 68px;
    box-shadow: 0 2px 30px 2px rgba(255,0,51,.41);
  }
  70% {
    width: 68px;
    height: 68px;
    box-shadow: 0 2px 30px 2px rgba(255,0,51,.41);
  }
  100% {
    height: 68px;
    width: 340px;
    box-shadow: 0 2px 30px 2px rgba(255,0,51,.41);
  }
}

@keyframes formInputLine {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@keyframes alertIntro {
  0% {
		transform: scale(0.9);
  }
	40% {
		transform: scale(1.1);
	}
  100% {
		transform: scale(1);
  }
}

@keyframes rightmove {
  0% {
		left: 0;
  }
	10% {
		left: 10px;
	}
  20% {
		left: 0;
  }
}
