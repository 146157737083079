@import "partials/variable";
%flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.flex {
  @extend %flexbox;
}

.align-items-center {
  align-items: center;
	-webkit-align-items: center;
}

.align-items-stretch {
  align-items: stretch;
	-webkit-align-items: stretch;
}

.flex-wrap {
	flex-wrap: wrap;
}

i.animated {
	animation: rightmove 2s infinite ease-out;
}

// HOME
#home-slide {
	margin-top: 150px;
  //height: calc(100vh - 150px);
  //background-color: rgba(0,0,0,0.1);
  //position: relative;
  //justify-content: center;
  //flex-direction: column;
  h1 + p {
    font-size: 2em;
    line-height: 24px;
    font-weight: 300;
    margin-top: 30px;
  }
  .btn {
		//justify-content: center;
		//width: 68px;
		position: relative;
		//animation: loadButton 2s infinite linear;
		white-space: nowrap;
    font-size: 1.8em;
    line-height: 18px;
    font-weight: 700;
    margin-top: 120px;
		color: transparent;
		animation: loadTextButton .5s 1 3s linear;
		animation-fill-mode: forwards;
		background-color: transparent;
		width: 340px;
    i {
      font-size: 2em;
      line-height: 18px;
      margin-left: 50px;
			position: relative;
    }
		&::after {
			animation: loadButton 1s 1 2.25s ease-in;
			animation-fill-mode: forwards;
			content: "";
			//width: 40px;
			//height: 40px;
			border-radius: 34px;
			background: rgba($color-primary,.85);
			position: absolute;
			z-index: -1;
			left: 0;
			top: 0;
		}
  }
}
.clip-circle-contain {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  .clip-circle {
    position: relative;
    left: 0;
    //animation: pulse 4s infinite ease-out;
    //clip-path: circle(50% at center);
    max-width: none;
    height: 110%;
    bottom: 10%;
		border-radius: 50%;
  }
}

.clip-circle-contain {
	top: 0;
  //width: 100vw;
  height: 100vw;
	//max-width: 105vh;
	max-height: 1000px;
	position: absolute;
	z-index: 1;
	right: 0;
	//left: 50%;
	figure {
		position: absolute;
    z-index: 10;
    display: -ms-flexbox;
    display: flex;
    height: 70vh;
    align-items: center;
    left: 0;
    right: 0;
    overflow: hidden;
    top: 15vh;
    width: calc(50vw - 100px);
    justify-content: center;
    border-radius: 8px;
		animation: clipFigure 1s ease-in;
		animation-fill-mode: forwards;
		opacity: 0;
		img {
			height: 100%;
	    position: absolute;
	    max-width: none;
			transition: all 1s ease;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

.circle-top {
	background-color: rgba($color-primary, 0.5);
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	top: -30%;
	z-index: 1;
	left: 150px;
	animation: clipTop 1s ease-in;
	animation-fill-mode: forwards;
	opacity: 0;
	&::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 0, 51, 0.5);
    border-radius: 50%;
    top: 5%;
    z-index: -10;
    left: 5%;
	}
}

// PAGINAS INTERNAS
.page-int-header {
	//display: flex;
  //align-items: center;
	//height: calc(100vh - 400px);
  margin-top: 100px;
	//margin-bottom: 150px;
	//min-height: 400px;
	position: relative;
  h1.title {
		opacity: 0;
    position: relative;
    display: inline-block;
    margin-bottom: 80px;
		animation: textInt 1s  ease-in;
		animation-fill-mode: forwards;
    &::after {
      content: "";
      margin-top: 30px;
			animation: lineTitle 1.5s 1 ease;
			animation-fill-mode: forwards;
			animation-delay: .5s;
      height: 3px;
      background-color: $color-primary;
      display: block;
      position: absolute;
      border-radius: 1.5px;
      box-shadow: 0 1px 15px 1px rgba($color-primary,.45);
    }
  }
  p {
		position: relative;
		opacity: 0;
		animation: textInt 1s 1 .25s ease-in;
		animation-fill-mode: forwards;
    font-size: 2em;
    color: $color-general;
    line-height: 32px;
		font-weight: 300;
  }
  p+p {
    margin-top: 15px;
  }
	&.page-int-noimg {
		margin-bottom: 150px;
		&::before {
			bottom: -400px;
		}
		+ section {
			position: relative;
			z-index: 1;
			&::after {
				content: "";
		    position: absolute;
		    left: 50px;
		    right: 50px;
		    top: -80px;
		    height: 500px;
		    background-color: #FFF;
		    z-index: -5;
				box-shadow: 0 -20px 50px -12px rgba(64, 66, 68, 0.15);
				border-radius: 8px;
			}
		}
	}
	&.page-int-contact {
		img {
			border-radius: 8px 8px 0 0;
		}
		&::before {
			bottom: 500px;
		}
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: -210px;
		bottom: 180px;
		transition: all .95s ease;
		//background-color: rgba(221, 233, 241, 0.35);
		background: rgb(242,247,249); /* Old browsers */
		background: -moz-linear-gradient(-90deg, rgb(242,247,249) 50%, rgb(221,233,241) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(-90deg, rgb(242,247,249) 50%,rgb(221,233,241) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(180deg, rgb(242,247,249) 50%,rgb(221,233,241) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f7f9', endColorstr='#dde9f1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		z-index: -100;
		clip-path: ellipse(100% 100% at 20% 0%);
	}
	.img-content {
		margin-top: 50px;
    height: 400px;
    overflow: hidden;
    border-radius: 8px;
		box-shadow: 0 15px 40px 5px rgba($color-general,.25);
		justify-content: center;
		&:hover {
			img {
				transform: scale(1.05);
			}
		}
		img {
			transition: all .25s ease;
			//min-width: 100vh;
		}
	}
}

.pageEnter {
	.page-int-header {
		&::before {
			//transform: translateY(-100%);
			transform: scale(3);
		}
	}
}

h2.subtitle {
	opacity: 0;
  position: relative;
  display: inline-block;
  margin-bottom: 133px;
	animation: textInt 1s ease-in;
	animation-fill-mode: forwards;
	animation-delay: .75s;
  &::after {
    content: "";
    width: 0px;
    height: 3px;
    background-color: rgba($color-primary, 0.85);
    display: block;
    position: absolute;
    right: 0;
		left: 0;
		margin: 30px auto 0 auto;
    border-radius: 1.5px;
    box-shadow: 0 2px 20px 2px rgba($color-primary,.25);
		animation: lineSubtitle 1.5s ease;
		animation-fill-mode: forwards;
		animation-delay: 1.5s;
  }
}

.group-card {
	position: relative;
	padding: 30px 30px 30px 51px;
	border: 1px solid #DADADA;
	margin: 0 2em;
	border-radius: 8px;
	min-height: 100%;
	a {
		display: block;
		margin-top: 10px;
		color: $color-primary;
		opacity: .75;
		transition: all .2s ease;
		&:hover {
			opacity: 1;
		}
	}
	&.no-border {
		background-color: #F9F9F9;
		border: none;
		p b {
			margin-bottom: 0px;
		}
	}
	.icon-card {
		position: absolute;
		width: 52px;
		height: 52px;
		border-radius: 26px;
		top: 20px;
		left: -26px;
		background-color: $color-primary;
		border: 5px solid #FFF;
		justify-content: center;
	}
	p {
		font-size: 1.6em;
		line-height: 24px;
		color: $color-general-claro;
		b {
			color: $color-general;
			font-weight: 700;
			display: block;
			margin-bottom: 10px;
		}
	}
}

// About Page

.logos-clientes {
	justify-content: center;
	figure {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px 0;
	}
}

// ProjectPage

.project-group {
	margin: 0 2em;
	.project-img {
		position: relative;
		box-shadow: 0 4px 40px 10px rgba($color-general-claro,.25);
		border-radius: 16px;
		img {
			-webkit-clip-path: inset(0px round 16px);
      clip-path: inset(0px round 16px);
			display: block;
			margin: 0 auto;
		}
		.icon-play {
			position: absolute;
			right: 20px;
			bottom: -36px;
			width: 72px;
			height: 72px;
			border-radius: 36px;
			background-color: #FFF;
			justify-content: center;
			box-shadow: 0 1px 20px 5px rgba($color-general-claro,.16);
			svg {
				margin-left: 4px;
			}
		}
	}
	.project-video {
		video {
			max-width: 100%;
			border-radius: 8px;
		}
	}
	.project-group-content {
		margin-top: 50px;
		h5 {
			font-size: 3.2em;
			line-height: 32px;
			font-weight: 700;
		}
		h5 + p {
			font-size: 1.8em;
			color: $color-general-claro;
			line-height: 24px;
			margin-top: 20px;
		}
	}
}

// Contact Section
.contact-section {
	padding: 80px 0 !important;
	position: relative;
	.icon-contact {
		position: absolute;
		z-index: 10;
		width: 52px;
		height: 52px;
		border-radius: 26px;
		top: -26px;
		left: 50%;
		margin-left: -26px;
		background-color: #E84545;
		border: 5px solid #FFF;
		justify-content: center;
	}
	p {
		text-align: center;
		letter-spacing: 0.5px;
		a {
			color: #FFF;
			line-height: 44px;
			font-size: 3.6em;
			font-weight: 700;
			text-shadow:
				-2px -2px #E84545,
    		-2px 2px #E84545,
    		2px -2px #E84545,
    		2px 2px #E84545;
			//background-size: 1px 1em;
		  box-shadow: inset 0 -4px #E84545, inset 0 -5.5px #FFF;
		  display: inline;
		}
		span {
			display: block;
			margin-top: 6px;
			line-height: 32px;
			font-size: 2.6em;
			color: #FFF;
		}
	}
	&::after {
		content: "";
		//background-image: url("/swirl_pattern.png");
		//opacity: 0.35;
	  top: 0;
	  left: 0;
	  bottom: -50px;
	  right: 0;
	  position: absolute;
	  z-index: -1;
		background-color: #E84545;
	}
}

.contact-social {
	justify-content: space-between;
  margin-top: 3em;
	a {
		width: 48%;
		display: inline-block;
		color: #FFF;
		text-align: center;
		font-size: 1.5em;
		padding: 20px 0;
		border-radius: 40px;
		font-weight: 700;
		&.contact-messenger { background-color: rgb(0, 132, 255); }
		&.contact-whatsapp { background-color: rgb(0, 216, 86); }
	}
}

// FAQ Section
.group-faq {
	p {
		color: $color-general-claro;
		b {
			color: $color-general;
			font-weight: 700;
		}
	}
	.group-faq-title {
		margin: 60px 0;
		p {
			font-size: 2.2em;
			line-height: 28px;
		}
	}
	.group-faq-content {
		p {
			a {
				color: $color-primary;
			}
			b {
				display: block;
				margin: 10px 0;
				line-height: 26px;
			}
			font-size: 2em;
			font-weight: 300;
			color: $color-general;
			line-height: 36px;
		}
		.column:first-child {
			p b {
				margin-top: 0px;
			}
		}
	}
}

// HOSTING & CLOUD
.hosting-group {
	position: relative;
	padding: 40px;
	border: 1px solid #DADADA;
	margin: 0 2em;
	border-radius: 8px;
	min-height: 100%;
	p {
		font-size: 2.4em;
		color: $color-general;
		font-weight: 700;
		display: block;
		span {
			color: $color-general-claro;
			font-weight: 400;
			text-decoration: line-through;
		}
		sup {
			vertical-align: super;
			font-size: 0.5em;
		}
	}
	ul {
		margin: 30px 0;
		li {
			font-size: 1.5em;
			line-height: 28px;
			color: $color-general-claro;
			@extend %flexbox;
			align-items: center;
			position: relative;
			padding-left: 30px;
			i {
				position: absolute;
	    	left: 0;
				font-size: 20px;
			}
		}
	}
	a {
		color: $color-general-claro;
		background-color: rgba($color-general-claro, .25);
		justify-content: space-between;
		font-size: 1.6em;
		line-height: 16px;
		font-weight: 700;
		padding: 12px 20px;
		margin-top: 30px;
		transition: all 1s ease;
		i {
			margin-left: 15px;
		}
		&:hover {
			box-shadow: 0 2px 30px 5px rgba($color-primary,.35);
		}
	}
}

.plan-group {
	position: relative;
	padding: 40px;
	border: 1px solid #DADADA;
	margin: 0 2em;
	border-radius: 8px;
	transition: all .5s ease;
	background-color: #FFF;
	min-height: 100%;
	p {
		font-size: 1.6em;
		color: $color-general;
		font-weight: 400;
		text-align: center;
		b {
			font-weight: 700;
		}
		&.plan-price {
			font-size: 6em;
	    display: block;
	    margin: 20px 0 0;
		}
		span {
			font-weight: 400;
			color: $color-general-claro;
		}
		sup {
			vertical-align: super;
			font-size: 0.5em;
		}
	}
	ul {
		margin: 30px 0;
		li {
			font-size: 1.5em;
			line-height: 36px;
			color: $color-general-claro;
			text-align: center;
		}
	}
	a, button {
		width: 100%;
		//color: #FFF;
		//background-color: $color-general-claro;
		font-size: 1.6em;
		line-height: 52px;
		font-weight: 700;
		padding: 0 20px;
		transition: all 1s ease;
		i {
			margin-left: 15px;
			position: relative;
			top: 6px;
		}
		&:hover {
			background-color: rgba($color-primary, .95);
		}
	}
	button::after {
		content: "trending_flat";
		margin-left: 15px;
		position: relative;
		top: 6px;
		font-family: 'Material Icons';
	  font-weight: normal;
	  font-style: normal;
	  font-size: 24px;
	  display: inline-block;
	  line-height: 1;
	  text-transform: none;
	  letter-spacing: normal;
	  word-wrap: normal;
	  white-space: nowrap;
	  direction: ltr;
	  -webkit-font-smoothing: antialiased;
	  text-rendering: optimizeLegibility;
	  -moz-osx-font-smoothing: grayscale;
	  font-feature-settings: 'liga';
	}
	&:hover {
		box-shadow: 0 2px 80px 2px rgba($color-general,.15);
	}
}

.plan-footer {
	font-size: 1.5em;
	color: $color-general-claro;
	margin-top: 2em;
	line-height: 28px;
	i {
		position: relative;
    top: 5px;
    margin-right: 6px;
	}
}

.radio-plan {
	display: block;
	width: 242px;
  margin: 0 auto 80px;
	position: relative;
	border-radius: 28px;
	border: 1px solid #DADADA;
	button {
		outline: none;
		font-family: 'Lato', sans-serif;
		font-size: 1.4em;
		line-height: 14px;
		font-weight: 700;
		border: none;
		background-color: rgba(0,0,0,0);
		border-radius: 28px;
		width: 100px;
		padding: 20px 0;
		cursor: pointer;
		color: $color-general-claro;
		letter-spacing: 0.5px;
		transition: all .2s ease;
		height: 56px;
		position: relative;
		z-index: 1;
		&:hover {
			color: $color-general;
		}
	}
	&::after {
		content: "";
		display: block;
		height: 58px;
		width: 142px;
		background-color: #FFF;
		box-shadow: 0 2px 40px 2px rgba($color-general,.15);
		border-radius: 29px;
		position: absolute;
		top: -1px;
	}
	&.check-izq button:first-child {
		color: $color-primary;
		width: 140px;
	}
	&.check-izq::after {
		animation: planButton .5s linear;
		animation-fill-mode: forwards;
	}
	&.check-der button:last-child {
		color: $color-primary;
		width: 140px;
	}
	&.check-der::after {
		animation: planButtonReverse .5s linear;
		animation-fill-mode: forwards;
	}
}

.form-service {
	position: fixed;
	overflow: scroll;
  z-index: 20;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //background-color: rgba($color-general,.7);
  display: flex;
  align-items: flex-start;
  //align-items: center;
  align-content: center;
	// form.form-contact {
	// 	background-color: #FFF;
  //   border-radius: 8px;
  //   padding: 60px;
	// 	margin: 50px auto;
	// 	h2.subtitle {
	// 		text-align: center;
	// 		display: block;
	// 		margin-bottom: 80px;
	// 		animation-delay: 0s;
	// 		animation: none;
	// 		opacity: 1;
	// 	}
	// 	.form-group {
	// 		margin-bottom: 20px;
	// 	}
	// 	span {
	// 		color: #8D9398;
  //   	font-size: 1.6em;
	// 		margin-left: 5px;
	// 		a {
	// 			color: rgba($color-primary,.85);
	// 		}
	// 	}
	// }
	.form-service-popup {
		background-color: #FFF;
	  border-radius: 8px;
	  padding: 60px;
	  margin: 50px auto;
	  h2.subtitle {
	    text-align: center;
	    display: block;
	    margin-bottom: 80px;
	    animation-delay: 0s;
	    animation: none;
	    opacity: 1;
	  }
	}
	.hide-form {
		position: fixed;
		left: 0;
	  right: 0;
	  top: 0;
	  bottom: 0;
		background-color: rgba($color-general,.7);
		z-index: -1;
	}
}

.form-renovacion {
	padding: 80px 0;
	h2 {
		text-align: center;
		display: block;
	}
}

.btn-form {
	float: right;
	padding: 10px 20px;
	border-radius: 4px;
}

.btn-form[disabled] {
	cursor: not-allowed;
	background-color: #dde9f1;
	opacity: .5;
	&:hover i {
		right: 0;
	}
}

.tipoCambio {
	text-align: right;
  margin-bottom: 20px;
  font-size: 1.4em;
  justify-content: flex-end;
  position: relative;
	select {
		border: 1px solid #dadada;
    background-color: transparent;
    font-size: 0.9em;
    color: #404244;
    margin-left: 12px;
    padding-left: 12px;
    width: 85px;
    -webkit-appearance: none;
    border-radius: 20px;
    height: 28px;
    outline: none;
	}
	i {
		position: relative;
    left: -30px;
    z-index: -1;
    font-size: 1.4em;
	}
}

.error-message {
	position: absolute;
	color: $color-primary;
	font-size: 1.4em;
	bottom: -20px;
	bottom: -20px;
  width: 100%;
	padding-left: 10px;
  text-align: right;
}

// Bancos Section
.group-banco {
	p {
		color: $color-general-claro;
		b {
			color: $color-general;
			font-weight: 700;
		}
	}
	.group-banco-title {
		margin: 60px 0;
		p {
			font-size: 2.2em;
			line-height: 28px;
			&.banco-bbva b { color: #1f7fea; }
			&.banco-bcp b { color: #FF5602; }
			&.banco-interbank b { color: #8B0000; }
		}
	}
	.group-banco-content {
		p {
			a {
				color: $color-general;
			}
			b {
				display: block;
				margin-bottom: 10px;
			}
			font-size: 1.6em;
			line-height: 18px;
		}
		div + div {
			margin-top: 30px;
		}
	}
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
//
// .fadepage-enter {
//   opacity: 0.01;
// }
//
// .fadepage-enter.fadepage-enter-active {
//   opacity: 1;
//   transition: opacity 1000ms ease-in;
// }

// .fade-leave {
//   opacity: 1;
// }
//
// .fade-leave.fade-leave-active {
//   opacity: 0.01;
//   transition: opacity 300ms ease-in;
// }

// REDES SOCIALES
.social-media {
	opacity: 0;
  position: relative;
	animation: textInt 1s 1 2.5s ease-in;
  animation-fill-mode: forwards;
	margin-top: 60px;
	a:not(:last-child) {
		margin-right: 10px;
	}
	a {
	  width: 48px;
	  height: 48px;
	  justify-content: center;
		&::after {
			content: "";
			width: 0;
			height: 0;
			border-radius: 24px;
			background-color: #FFF;
			box-shadow: 0px 0px 0px 0px rgba($color-general,0);
			position: absolute;
			z-index: -1;
			transition: all .25s ease;
		}
		&:hover::after {
			width: 48px;
			height: 48px;
			box-shadow: 0px 1px 6px 1px rgba($color-general, 0.1);
		}
		svg > g { transition: all .25s ease; }
		&.icon-facebook:hover svg g { fill: #2d5fca; }
		&.icon-twitter:hover svg g { fill: #6bbfff; }
		&.icon-linkedin:hover svg g { fill: #0490d2; }
		&.icon-whatsapp:hover svg g { fill: #42ca3b; }
	}
}

// BACK CIRCLE
.back-1-circle {
	animation: clipCircle .8s ease-out;
	position: absolute;
	z-index: -10;
	top: 0;
	width: 100vw;
  height: 900px;
  //background-color: #f2f7f9;
	background: rgb(242,247,249); /* Old browsers */
	background: -moz-linear-gradient(-70deg, rgb(242,247,249) 50%, rgb(221,233,241) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-70deg, rgb(242,247,249) 50%,rgb(221,233,241) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(160deg, rgb(242,247,249) 50%,rgb(221,233,241) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f7f9', endColorstr='#dde9f1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	-webkit-clip-path: ellipse(100% 100% at 5% 0%);
  clip-path: ellipse(100% 100% at 5% 0%);
}
.back-2-contain {
	position: absolute;
	overflow: hidden;
	width: 400px;
	height: 800px;
	top: 0;
	right: 0;
}
.back-2-circle {
	position: absolute;
	z-index: -5;
	top: -200px;
	right: -400px;
	width: 800px;
	height: 800px;
	border-radius: 50%;
	background-color: rgba($color-primary,.75);
}

.form-content {
	position: relative;
	padding: 60px 80px 0 80px;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 200px;
		background-color: #FFF;
		border-radius: 0;
		box-shadow: 0 -20px 50px -12px rgba($color-general, 0.15);
		z-index: -1;
	}
}

// FORMULARIO
.form-contact {
	padding: 50px 0;
	.form-title {
		font-size: 0.75em;
		margin-bottom: 50px;
		p {
			line-height: 44px;
			letter-spacing: normal;
		}
	}
	// .form-group {
	// 	position: relative;
	// 	width: 100%;
	// 	label,input,textarea,select {
	// 		display: block;
	// 		width: 100%;
	// 		font-family: 'Lato', sans-serif;
	// 		font-size: 1.8em;
	// 		padding-left: 10px;
	// 		line-height: 24px;
	// 		position: relative;
	// 		outline: none;
	// 		box-shadow: none;
	// 	}
	// 	label {
	//     //top: 27px;
	// 		//color: #8D9398;
	// 		//transition: all .25s ease;
	// 		//z-index: 1;
	// 	}
	// 	input {
	// 		border: none;
	// 		background-color: rgba(0,0,0,0);
	// 		color: $color-general;
	// 		font-weight: 400;
	// 		padding: 5px 10px 15px;
	// 		z-index: 5;
	// 		&:focus {
	// 			outline: none;
	// 		}
	// 		&[name="nombre"] {
	// 			text-transform: capitalize;
	// 		}
	// 	}
	// 	input[type=number]::-webkit-outer-spin-button,
	// 	input[type=number]::-webkit-inner-spin-button {
	// 	    -webkit-appearance: none;
	// 	    margin: 0;
	// 	}
	// 	input[type=number] {
	// 	    -moz-appearance:textfield;
	// 	}
	// 	select {
	// 		background-color: #fff;
	// 		border: none;
	// 		padding: 5px 25px 15px 10px;
  //   	width: calc(100% - 3px);
	// 		color: $color-general;
	// 		appearance: none;
	// 		position: relative;
	// 		+ i {
	// 			position: absolute;
	// 			right: 0;
	// 			bottom: 13px;
	// 		}
	// 	}
	// 	&::after, &::before {
	// 		content: "";
	// 		position: absolute;
	// 		//width: 100%;
	// 		height: 1px;
	// 		background-color: #DEE4E8;
	// 		bottom: 0;
	// 		top: auto;
	// 		z-index: 1;
	// 	}
	// 	&::before {
	// 		left: 0;
	// 		right: 0;
	// 	}
	// 	&::after {
	// 		//left: -100%;
	// 		background-color: $color-general-claro;
	// 		z-index: 2;
	// 		transition: all .25s ease-in;
	// 	}
	// 	&.active::after {
	// 		left: 0;
	// 		//animation: formInputLine .75s ease-in;
	// 	}
	// 	&.active label {
	// 		//z-index: 1;
	// 		//top: 0;
	// 		//color: #8D9398;
	// 		//font-size: 1.6em;
	// 	}
	// 	&:not(:last-child) {
	// 		margin-bottom: 30px;
	// 	}
	// }
	.link {
		margin-top: 15px;
	}
}

#home-services {
	margin-top: 300px;
	p.title {
		font-size: 4.2em;
	}
	.services-group {
		margin-top: -6em;
		.column {
			padding: 6em 1em 0 1em;
		}
	}
	.home-services-group {
		padding-right: 50px;
		//margin-bottom: 50px;
		h5 {
			margin: 30px 0;
		}
		p {
			color: $color-general-claro;
			font-size: 1.6em;
			line-height: 24px;
		}
	}
	a {
		margin-top: 40px;
	}
}

#home-contact {
	position: relative;
	//overflow: hidden;
	&::after {
		content: "";
		background-image: url('/img/contact.jpg');
		z-index: -1;
		position: absolute;
		left: 0;
		top: 0;
		right: 60%;
		bottom: -50px;
		background-size: cover;
    background-position: 50% 50%;
	}
}

.group-otras-cuentas {
	font-size: 1.6em;
	line-height: 24px;
	padding-left: 40px;
	position: relative;
	padding-right: 20px;
  margin-left: 20px;
	&::before {
		content: "done";
		position: absolute;
		left: 0;
		top: 2px;
		font-family: 'Material Icons';
	  font-weight: normal;
	  font-style: normal;
	  font-size: 24px;
	  display: inline-block;
	  line-height: 1;
	  text-transform: none;
	  letter-spacing: normal;
	  word-wrap: normal;
	  white-space: nowrap;
	  direction: ltr;
	  -webkit-font-smoothing: antialiased;
	  text-rendering: optimizeLegibility;
	  -moz-osx-font-smoothing: grayscale;
	  font-feature-settings: 'liga';
	}
}

.link-politicas {
	margin-bottom: 60px;
	position: relative;
	button {
		text-align: center;
		font-size: 1.7em;
		color: $color-general;
		width: 100%;
		height: 100%;
		border-bottom: 1px solid #DEE4E8;
		padding-bottom: 20px;
		transition: all .25s ease;
		&.label-active {
			//font-size: 1.8em;
			color: $color-primary;
			border-color: $color-primary;
		}
	}
	&::before {
		content: "";
		width: 33.33%;
		position: absolute;
		height: 1px;
		//background-color: red;
		bottom: 0;
		left: 0;
	}
}

#map {
	position: relative;
	margin-top: 0;
	height: 500px;
	> div {
		height: 550px !important;
		&::before {
			//content: "";
			display: block;
			position: absolute;
			background-color: rgba(0,0,0,0);
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
		}
	}
}

.alert {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.5);
	z-index: 100;
	justify-content: center;
	.content-alert {
		background-color: #FFF;
		padding: 60px 100px 40px;
		border-radius: 8px;
		font-size: 2.4em;
		text-align: center;
		animation: alertIntro .25s ease-in;
		max-width: 600px;
		h6 {
			font-weight: 700;
    	margin-bottom: 15px;
    	letter-spacing: 0.5px;
			color: $color-general;
		}
		p {
			color: $color-general-claro;
			font-size: 0.8em;
			line-height: 24px;
		}
		button {
			font-size: .8em;
			color: #FFF;
			background-color: rgba($color-primary,.85);
			padding: 15px 60px;
			line-height: 20px;
			margin-top: 40px;
		}
	}
}

@media all and (min-width: 75em) {
	.back-2-circle {
		//right: -200px;
	}
	#home-contact::after {
    right: 50%;
	}
	.page-int-header.page-int-contact img {
    border-radius: 8px 0 0 8px;
	}
	.form-content {
		position: relative;
		padding: 50px 40px 0;
		z-index: 1;
		&::before {
			left: -2em;
			right: -2em;
			border-radius: 0 8px 8px 0;
		}
	}
}
@media all and (max-width: 62em) {
	.clip-circle-contain {
    display: none;
	}
	.flex-order-5 {
		order: 5;
	}
	.flex-order-4 {
		order: 4;
	}
	.about-footer {
		text-align: center;
	}
	#home-contact {
		margin-top: 500px;
		&::after {
	    top: -400px;
	    height: 400px;
	    bottom: 0;
			right: 0;
		}
	}
}

@media all and (max-width: 48em) {
	#root {
		overflow: hidden;
	}
	.logos .logotipo:first-child {
		display: none;
	}
	.logos .logotipo:nth-child(2) {
	  display: block !important;
	}
	.page-int-header .img-content {
    height: 300px;
	}
	header {
		justify-content: space-between;
		nav {
			position: absolute;
			right: 0;
	    transform: translateX(200%);
	    flex-direction: column;
	    top: 50px;
	    background-color: #FFF;
	    border-radius: 8px;
			align-items: stretch;
			z-index: 10;
			box-shadow: 0 4px 10px 2px rgba($color-general,.15);
			//display: none !important;
			transition: all .25s linear;
			&.menuActivo {
				//display: block !important;
				transform: translateX(0);
			}
			li {
	    	margin-right: 0 !important;
				width: 100%;
				text-align: right;
				a {
					padding: 20px 40px 20px 80px;
					display: block;
				}
				&:not(:last-child) {
					border-bottom: 1px solid #EEE;
				}
			}
		}
		button {
			display: block !important;
		}
	}
	.page-int-header.page-int-noimg + section::after {
		left: 10px;
		right: 10px;
	}
	.plan-group {
		margin: 0;
	}
	.form-content {
		position: relative;
		padding: 50px 40px 0;
		z-index: 1;
	}
	#map {
		height: 400px;
		> div {
			height: 450px !important;
		}
	}
}
@media all and (min-width: 34em) {
	.title {
		font-size: 4.8em !important;
	  line-height: 52px !important;
	}
}
@media all and (max-width: 34em) {
	header {
		margin: 45px 20px 0 !important;
	}
	.back-1-circle {
		height: 680px;
		clip-path: ellipse(100% 100% at 30% 0%);
	}
	.back-2-circle {
		top: -580px;
		right: -580px;
	}
	#home-slide {
    margin-top: 80px;
		.btn {
			margin-top: 60px;
			font-size: 1.6em;
			width: 280px;
			i {
				margin-left: 20px;
			}
			&::after {
				animation: none;
				width: 280px;
				height: 68px;
				box-shadow: 0 2px 30px 2px rgba(255,0,51,.41);
			}
		}
	}
	#home-services {
    margin-top: 150px;
	}
	#home-services p.title {
		font-size: 3.2em;
	}
	#home-services .home-services-group {
    padding-right: 0px;
	}
	.group-card {
    padding: 20px 20px 20px 35px;
    margin: 0;
		p {
			font-size: 1.5em;
		}
	}
	.page-int-header .img-content {
    height: 160px;
	}
	.page-int-header p {
		font-size: 1.8em;
		line-height: 28px;
	}
	section {
		padding: 0 20px !important;
	}
	.page-int-header {
    margin-top: 60px;
	}
	.page-int-header::before {
		bottom: 100px;
		clip-path: ellipse(100% 100% at 50% 0%);
	}
	.group-faq {
		font-size: 0.9em;
		.group-faq-title {
			margin: 40px 0;
		}
		.group-faq-content {
			font-size: 0.9em;
			p {
				line-height: 28px;
			}
		}
	}
	#map {
    padding: 0 !important;
	}
	#map > div {
    height: 650px !important;
	}
}
